/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import "../styles/VideoGallery.css";
import logo from "../images/logo.png";

interface Video {
    id: string;
    thumbnail: string;
    title: string;
    description: string;
}
// interface Passwords {
//     passwords: Password[];
// }
// interface Password {
//     name: string;
//     password: string;
// }

// Define an array of video data
const videos: Video[] = [
    {
        id: "-DKwJmk_3pc",
        thumbnail: "https://i.ytimg.com/vi/-DKwJmk_3pc/maxresdefault.jpg",
        title: "Complete Overview (05:06)",
        description: "Get the complete overview of the platform",
    },
    {
        id: "K-2cQRB6Qlw",
        thumbnail: "https://i.ytimg.com/vi/K-2cQRB6Qlw/maxresdefault.jpg",
        title: "Overview of the Dashboard (01:27)",
        description: "Deep dive into the dashboard and key insights",
    },
    {
        id: "6ssE3uy8qIg",
        thumbnail: "https://i.ytimg.com/vi/6ssE3uy8qIg/maxresdefault.jpg",
        title: "New Program for Group (02:24)",
        description: "Create a new program for a group of mentees",
    },
    {
        id: "x_iLXLppJ30",
        thumbnail: "https://i.ytimg.com/vi/x_iLXLppJ30/maxresdefault.jpg",
        title: "New Program for 1-on-1 (02:44)",
        description: "Create a new program for a 1-on-1 sessions with the mentee",
    },
];

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "76e07b93-4f54-4118-afe3-9488340775ff",
        enableAutoRouteTracking: true,
    },
});

appInsights.loadAppInsights();

// Define the VideoGallery component
const VideoGallery: React.FC = () => {
    const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

    useEffect(() => {
        appInsights.trackPageView();
    }, []);

    const handleVideoClick = (video: Video) => {
        appInsights.trackEvent({ name: video.title, properties: { videoId: video.id } });
        setSelectedVideo(video);
    };

    const handleVideoClose = () => {
        setSelectedVideo(null);
    };

    return (
        <div className="app">
            <img src={logo} alt="Logo" className="logo" />
            <h1>Video Gallery</h1>
            <div className="gallery">
                {videos.map((video) => (
                    <div className="card" key={video.id} onClick={() => handleVideoClick(video)}>
                        <div className="card-thumbnail">
                            <img src={video.thumbnail} alt={video.title} />
                        </div>
                        <div className="card-content">
                            <h2>{video.title}</h2>
                            <p>{video.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            {selectedVideo && (
                <div className="modal">
                    <div className="modal-background" onClick={handleVideoClose}></div>
                    <div className="modal-content">
                        <iframe
                            width="1280"
                            height="720"
                            src={`https://www.youtube.com/embed/${selectedVideo.id}`}
                            title={selectedVideo.title}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                        <button className="primary-btn" onClick={handleVideoClose}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoGallery;
