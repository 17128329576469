import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import VideoGallery from "../components/VideoGallery";

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "76e07b93-4f54-4118-afe3-9488340775ff",
        enableAutoRouteTracking: true,
    },
});
appInsights.loadAppInsights();

const Gallery: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    let decodeTokenData: any;

    if (location && location.state) {
        localStorage.setItem("jwt", location.state.jwt);
        decodeTokenData = decodeToken(location.state.jwt);
        console.log(decodeTokenData);
    }

    const sendContactDataToBackend = async () => {
        appInsights.setAuthenticatedUserContext(decodeTokenData.name, decodeTokenData.email, true);
        appInsights.trackTrace({ message: "UserDetails" }, { name: decodeTokenData.name, email: decodeTokenData.email });
    };

    useEffect(() => {
        if (decodeTokenData === undefined || decodeTokenData.valid === false) {
            navigate("/");
        } else {
            sendContactDataToBackend();
        }
    }, [decodeTokenData, navigate]);

    useEffect(() => {
        appInsights.trackPageView();
    }, []);

    return (
        <div>
            <VideoGallery />
        </div>
    );
};

export default Gallery;
