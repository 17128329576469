import { useState } from "react";
import "../styles/signup.css";
import { useNavigate } from "react-router-dom";
import sign from "jwt-encode";

const Signup = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {
        const data = {
            name: name,
            email: email,
            valid: true,
        };
        const jwt = sign(data, "secret");
        navigate("/gallery", { state: { jwt } });
    };

    return (
        <>
            <section className="gallery-top banner-res wrapper pd-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 top-align">
                            <div className="cover-top">
                                <h2>Demo Videos</h2>
                                <p className="gallery-details">
                                    Checkout our demo videos to see how Mentorange can help you in your mentoring journey. These videos will help you
                                    understand how Mentorange can be useful.
                                </p>
                            </div>
                            <form className="frm" onSubmit={handleSubmit}>
                                <input
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                                <input
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    className="email"
                                />
                                <button id="form-submit" className="snd-msg mt-4" type="submit">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Signup;
