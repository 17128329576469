import Signup from "../components/Signup";
// import VideoGallery from "../components/VideoGallery";

const Home: React.FC = () => {
    return (
        <div>
            <Signup />
        </div>
    );
};

export default Home;
